.chat-container {
  display: flex;
  height: 80vh;
  gap: 0.8rem;
}

.user-list {
  width: 25%;
  background-color: #fff;
  color: rgb(0, 0, 0);
  padding: 15px;
  border-radius: 10px;
}

.user-id {
  /* padding: 10px; */
  cursor: pointer;
  color: rgb(0, 0, 0);
  font-weight: 600;
  display: flex;
  align-items: center;
}

.user-id img.avatar {
  width: 30px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-id.active {
  background-color: #9f9f9f;
  border-radius: 7px;
}

.chat-window {
  width: 75%;
  background-color: #fff;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.chat-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%; /* Use full height of the chat window */
  border-radius: 10px;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;

  padding-bottom: 60px;
  border-radius: 10px;
}

.message {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.message.sent {
  justify-content: flex-end;
}

.message.received {
  justify-content: flex-start;
  align-items: start;
}

.message .imgwrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.message img.avatar {
  width: 30px;
  height: 25px;
}

.message-content {
  padding: 10px 15px;

  max-width: 50%;

  /* color: #000; */
}
.received .message-content {
  border-radius: 0px 20px 20px 20px / 20px 20px 20px 20px;
}

.sent .message-content {
  border-radius: 15px 20px 0px 20px / 20px 20px 20px 20px;
}
.received .message-content .user-id {
  color: #5a5;
}
.sent .message-content .user-id {
  color: #bb830f;
}

.message.sent .message-content {
  background-color: rgba(171, 132, 15, 0.102);
}
.message.received .message-content {
  background-color: rgba(85, 170, 85, 0.2);
}

.message-input {
  display: flex;
  padding: 5px;
  background-color: #ceb071;
  border-top: 1px solid #ceb071; /* Optional: Add a border for separation */
  border-radius: 10px;
}

.message-input input {
  flex: 1;
  padding: 10px;
  border: none;
  background-color: #e6e6e6;
  color: rgb(0, 0, 0);
  border-radius: 3px;
}

.message-input button {
  padding: 10px;
  background-color: #5a5;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}
.no-chat-found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height:80vh;

}
/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .chat-container {
    flex-direction: column; /* Stack users list and chat window vertically */
    height: 100vh; /* Full height for mobile view */
  }

  .user-list {
    width: 100%; /* Full width for mobile */
    height: 30%; /* Adjust height as necessary */
    overflow-y: auto;
  }

  .chat-window {
    width: 100%; /* Full width for mobile */
    height: 70%; /* Adjust height as necessary */
  }

  .messages {
    padding: 10px; /* Adjust padding for mobile */
  }
}


.select-user{
    background: #f0f1ef;
    padding: 10px;
    border-radius: 6px;
}
.select-user:hover,
.select-user.active{
    background: #DFEEE0;
    
}

.select-user.active .user-id {
    color: #5a5;
  }
